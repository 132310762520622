import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import Icon from "~/assets/icons/Icon";
import { OpeningHours, StrapiMainCategory, StrapiStore } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { Heading } from "../Heading";
import { Text } from "../Text";
import { LinkButton } from "../LinkButton";
import { getToday } from "~/utils/openingHours";
import { getRoute } from "~/utils/getRoute";
import { NavigationButton } from "../NavigationButton";
import Button from "../Button";

type StoreCardProps = {
  store: StrapiStore;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
  hideButtons?: boolean;
};

export const StoreCard = ({
  store,
  className,
  closable,
  onClose,
  hideButtons,
}: StoreCardProps) => {
  const { name, storeImage, description } = store.attributes;
  return (
    <div
      className={classNames(
        "max-w-xs block mt-5 rounded-md px-6 pb-4 bg-white-500",
        className
      )}
    >
      <div
        className={classNames(
          "flex",
          storeImage.data ? "justify-between" : "justify-end"
        )}
      >
        {storeImage.data && (
          <Link href={`/butikker/${store.id}`}>
            <Image
              {...getFormattedImageAttributes(storeImage.data, "thumbnail")}
              alt={"Billede af " + name}
              className="rounded-full h-16 w-16 -translate-y-6 -mb-6 object-cover"
            />
          </Link>
        )}
        {closable && onClose && (
          <LinkButton
            theme="clear"
            className="pr-0 pt-2 pb-0 -mr-2 justify-self-end"
            onClick={onClose}
          >
            <Icon
              title="Luk butikskort"
              name="close"
              className="w-6 h-6 text-neutral-600"
            />
          </LinkButton>
        )}
      </div>
      <Link href={`/butikker/${store.id}`}>
        <Heading className="text-2xl my-2">{name}</Heading>
      </Link>
      <div className="flex items-center my-2 gap-5">
        <ShortOpeningTime openingHours={store.attributes.openingHours} />

        <ShortCategories
          mainCategories={store.attributes.mainCategories?.data}
        />
      </div>
      <Text className="mt-2 text-gray-500 text-ellipsis my-2 text-xs line-clamp-3">
        {description}
      </Text>

      {!hideButtons && (
        <div className="flex justify-between items-center mt-5">
          <Button
            href={getRoute("/stores/:id", { id: store.id })}
            className="text-base"
            variant="primary"
            size="lg"
          >
            Se butik
          </Button>

          <NavigationButton store={store} />
        </div>
      )}
    </div>
  );
};

const ShortOpeningTime = ({
  openingHours,
}: {
  openingHours?: OpeningHours;
}) => {
  const { today } = getToday(openingHours);
  const closingTime = today.hours.at(-1)?.to;

  return (
    <Text className="text-secondary-600 text-xs flex items-center">
      <Icon name="clock" className="mr-1 w-3.5 h-3.5 flex-shrink-0" />
      {closingTime ? <span> Til {closingTime}</span> : <span> Lukket</span>}
    </Text>
  );
};

const ShortCategories = ({
  mainCategories,
}: {
  mainCategories?: StrapiMainCategory[] | null;
}) => {
  const categories = mainCategories?.map(
    (category) => category.attributes.name
  );

  return (
    <Text className="text-secondary-600 text-xs flex items-center">
      <Icon name="tag" className="mr-1 w-3.5 h-3.5 flex-shrink-0" />
      <span>
        {categories && categories.length > 0 ? categories.join(", ") : "-"}
      </span>
    </Text>
  );
};
