import classNames from "classnames";
import { Card } from "~/components/Card";
import { SectionHeading } from "~/components/SectionHeading";
import { Slider } from "~/components/Slider";
import { StrapiStore } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getRoute } from "~/utils/getRoute";

type NewStoresSectionProps = {
  title: string;
  subtitle: string;
  stores: StrapiStore[];
  className?: string;
};

export const NewStoresSection = ({
  title,
  subtitle,
  stores,
  className,
}: NewStoresSectionProps) => {
  // show newest store first
  stores.sort((a, b) => b.id - a.id);

  if (!stores.length) return null;

  return (
    <div className={classNames(className)}>
      <SectionHeading>
        <SectionHeading.Title className="mb-5">{title}</SectionHeading.Title>
        <SectionHeading.Subtitle className="mb-20">
          {subtitle}
        </SectionHeading.Subtitle>
      </SectionHeading>
      <Slider
        slideClass="aspect-square"
        elements={stores.map((store) => {
          const { attributes } = store;
          const { storeImage, name, shownAddress } = attributes;
          const imageAttributes = storeImage.data?.attributes;
          return {
            id: store.id,
            component: (
              <Card href={getRoute("/stores/:id", { id: store.id })}>
                {imageAttributes ? (
                  <Card.Image
                    className="h-full w-full object-cover rounded-xl"
                    {...getFormattedImageAttributes(storeImage.data, "large")}
                  />
                ) : (
                  <div className="h-full rounded-xl bg-secondary-100 mb-3" />
                )}
                {shownAddress?.city && (
                  <Card.Location>{shownAddress.city}</Card.Location>
                )}
                <Card.Title>{name}</Card.Title>
              </Card>
            ),
          };
        })}
      />
    </div>
  );
};
