import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getRoute } from "~/utils/getRoute";
import { ProductsProps, ProductProps } from "./types";

const Products = ({ categories, className }: ProductsProps) => {
  return (
    <div
      className={classNames("grid grid-cols-2 md:grid-cols-4 gap-5", className)}
    >
      {categories.map((category) => (
        <Product key={category.id} category={category} />
      ))}
    </div>
  );
};

function Product({ category, className }: ProductProps) {
  const {
    attributes: { name, image },
  } = category;

  return (
    <Link
      href={getRoute("/categories/:categoryId/:categoryName", {
        categoryId: category.id,
        categoryName: name,
      })}
      className={className}
    >
      <div>
        <Image
          {...getFormattedImageAttributes(image.data, "medium", {
            altFallback: name,
          })}
          className="aspect-video rounded-xl object-cover object-center mb-2 lg:mb-5 w-full"
          priority
        />
        <h3 className="text-xl">{name}</h3>
      </div>
    </Link>
  );
}

export { Products, Product };
