import classNames from "classnames";
import { ProductsSlider } from "~/components/ProductsSlider";
import { SectionHeading } from "~/components/SectionHeading";
import { StrapiProduct } from "~/shared-types";

type FeaturedProductsSectionProps = {
  title: string;
  subtitle: string;
  products: StrapiProduct[];
  className?: string;
};

export const FeaturedProductsSection = ({
  title,
  subtitle,
  products,
  className,
}: FeaturedProductsSectionProps) => {
  if (!products.length) return null;

  return (
    <div className={classNames(className)}>
      <SectionHeading>
        <SectionHeading.Title className="mb-5">{title}</SectionHeading.Title>
        <SectionHeading.Subtitle className="mb-20">
          {subtitle}
        </SectionHeading.Subtitle>
      </SectionHeading>
      <ProductsSlider products={products} />
    </div>
  );
};
