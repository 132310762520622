import classNames from "classnames";
import Button from "../Button";
import Icon from "~/assets/icons/Icon";

type Props = {
  type: "left" | "right";
  className?: string;
};

export const NAVIGATION_OPTIONS = {
  prevEl: ".slider-left-arrow",
  nextEl: ".slider-right-arrow",
  disabledClass: "slider-disabled",
};

export const SliderButton = ({ type, className }: Props) => {
  const isLeft = type === "left";

  return (
    <Button
      variant="primary"
      className={classNames(
        className,
        "slider-nav-button",
        `slider-${type}-arrow`,
        type === "left" ? "left-0" : "right-0",
        "mx-1 shadow-md w-12 h-12 absolute top-1/2 -translate-y-1/2 z-10 hidden sm:flex justify-center items-center rounded-full border-none"
      )}
      aria-label={isLeft ? "Venstre pil" : "Højre pil"}
    >
      {isLeft ? (
        <Icon name="arrowLeft" title="Skift til næste" />
      ) : (
        <Icon name="arrowRight" title="Skift til forrige" />
      )}
    </Button>
  );
};
