import { OpenHoursContent, OpeningHours } from "~/shared-types";
import { getTime } from "~/utils/dateHandler";

export const WEEKDAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export const DANISH_WEEKDAYS = [
  "mandag",
  "tirsdag",
  "onsdag",
  "torsdag",
  "fredag",
  "lørdag",
  "søndag",
] as const;

export const getSortedHours = (hours: OpenHoursContent[]) => {
  return hours.sort((a, b) => {
    return Number(getTime(a.from)) - Number(getTime(b.from));
  });
};

export const formatHours = (hours: OpenHoursContent[]) =>
  hours
    .filter((hours) => hours.from && hours.to)
    .map((hours: OpenHoursContent) => `${hours.from} til ${hours.to}`)
    .join(", ");

export const getToday = (openingHours?: OpeningHours) => {
  const todayIndex = new Date().getDay();
  const mondayFirstIndex = todayIndex === 0 ? 6 : todayIndex - 1;
  // 0 = sunday, 6 = saturday
  const todayDay = WEEKDAYS[mondayFirstIndex];
  const todaysOpeningHours = openingHours?.[todayDay];

  const hoursType =
    todaysOpeningHours?.hours && todaysOpeningHours.hours?.length > 0
      ? todaysOpeningHours.type
      : "closed";

  return {
    today: {
      day: todayDay,
      danishDay: DANISH_WEEKDAYS[mondayFirstIndex],
      type: hoursType,
      hours: todaysOpeningHours?.hours
        ? getSortedHours(todaysOpeningHours.hours)
        : [],
    },
  };
};
