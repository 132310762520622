import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import { Heading, HTMLLevels } from "../Heading";
import { Text } from "../Text";
import {
  SectionHeadingProps,
  SectionHeadingSubtitleProps,
  SectionHeadingTitleProps,
} from "./types";
import classNames from "classnames";

const SectionHeading = ({ children }: SectionHeadingProps) => {
  return (
    <CompoundComponentContext.Provider value={"SectionHeading"}>
      {children}
    </CompoundComponentContext.Provider>
  );
};

function Title({
  level = HTMLLevels.h2,
  children,
  className,
}: SectionHeadingTitleProps) {
  useCompoundComponent("SectionHeading", "Title");

  return (
    <Heading
      level={level}
      className={classNames("text-4xl sm:text-5xl", className)}
    >
      {children}
    </Heading>
  );
}

function Subtitle({ children, className }: SectionHeadingSubtitleProps) {
  useCompoundComponent("SectionHeading", "Subtitle");

  return (
    <Text
      className={classNames(
        "text-base sm:text-2xl text-secondary-600",
        className
      )}
    >
      {children}
    </Text>
  );
}

SectionHeading.Title = Title;
SectionHeading.Subtitle = Subtitle;

export { SectionHeading };
