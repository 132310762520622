import Image from "next/image";
import { ExploreButton } from "~/components/ExploreButton";
import { SectionHeading } from "~/components/SectionHeading";
import { StoreCard } from "~/components/StoreCard";
import {
  StrapiImage,
  StrapiMedia,
  StrapiStore,
  StrapiVideo,
} from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getMediaType } from "~/utils/getMediaType";

type ExploreSectionProps = {
  title: string;
  subtitle: string;
  media: StrapiMedia | null;
  store: StrapiStore | null;
};

export const ExploreMedia = ({ media }: { media: StrapiMedia | null }) => {
  const mediaType = getMediaType(media);

  if (mediaType === "other") {
    return (
      <video
        playsInline
        autoPlay
        muted
        loop
        className="object-cover aspect-[16/7] rounded-md min-h-[740px] xs:min-h-[680px] lg:min-h-[580px] scale-[1.01]"
      >
        <source
          src="/local-scan.mp4"
          type="video/mp4"
          media="(min-width: 1080px)"
        />
        <source src="/local-scan-1080p.mp4" type="video/mp4" />
      </video>
    );
  }

  if (mediaType === "video") {
    return (
      <video
        playsInline
        autoPlay
        muted
        loop
        className="object-cover aspect-[16/7] rounded-md min-h-[740px] xs:min-h-[680px] lg:min-h-[580px] scale-[1.01]"
      >
        <source
          src={(media as StrapiVideo).attributes.url}
          type={(media as StrapiVideo).attributes.mime}
        />
      </video>
    );
  }

  return (
    <Image
      {...getFormattedImageAttributes(media as StrapiImage, "large", {
        altFallback: media?.attributes.name,
      })}
      className="object-cover aspect-[16/7] rounded-md min-h-[740px] xs:min-h-[680px] lg:min-h-[580px]"
    />
  );
};

export const ExploreSection = ({
  title,
  subtitle,
  media,
  store,
}: ExploreSectionProps) => {
  return (
    <div className="relative flex items-start xl:items-center mb-20 rounded-md overflow-hidden bg-secondary-100">
      <ExploreMedia media={media} />
      <div className="absolute inset-0 flex flex-col justify-center px-5 pt-24 pb-16 sm:px-10 md:p-10 md:pl-28">
        <SectionHeading>
          <SectionHeading.Title className="max-w-md mb-5">
            {title}
          </SectionHeading.Title>
          <SectionHeading.Subtitle className="max-w-md mb-28 sm:mb-10">
            {subtitle}
          </SectionHeading.Subtitle>
        </SectionHeading>
        <div className="grow lg:hidden" />
        <ExploreButton className="mt-10 order-1 xs:max-w-max lg:order-none" />
        {store && (
          <StoreCard
            store={store}
            className="lg:absolute lg:right-10 lg:bottom-10 w-full"
            hideButtons
          />
        )}
      </div>
    </div>
  );
};
