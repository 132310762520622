type CurrencyOptions = {
  amount?: string | number | null;
  prefix?: string;
  suffix?: string;
};

export function stringToCurrency({
  amount,
  prefix = "kr. ",
  suffix = "",
}: CurrencyOptions) {
  if (!amount) amount = 0;
  if (typeof amount === "string") amount = stringToNumber(amount);
  return (
    prefix +
    new Intl.NumberFormat("da-DK", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount) +
    suffix
  );
}

export function stringToNumber(amount: string | null) {
  if (!amount) return 0;
  return Number(amount.replaceAll(".", "").replace(",", "."));
}
