import { GetServerSideProps } from "next";
import { SectionHeading } from "~/components/SectionHeading";
import { Products } from "~/components/Products";
import {
  StrapiFrontPage,
  StrapiMainCategory,
  StrapiProduct,
  StrapiStore,
} from "~/shared-types";
import {
  findAllMainCategories,
  findAllStores,
  findRandomStores,
  findSingleType,
} from "~/strapi/api";
import { ExploreSection } from "./ExploreSection";
import { FeaturedProductsSection } from "./FeaturedProductsSection";
import { handleRequestError } from "~/utils/rollbar";
import { NewStoresSection } from "./NewStoresSection";
import { getProducts } from "~/api/products";
import { randomSort } from "~/utils/randomSort";
import { useSingleStoreAtom } from "~/atoms/singleStore";
import { useEffect } from "react";

type ServerSideProps = {
  page: StrapiFrontPage;
  categories: StrapiMainCategory[];
  stores: StrapiStore[];
  randomStore: StrapiStore | null;
  featuredProducts: StrapiProduct[];
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
  context
) => {
  const page = await findSingleType<StrapiFrontPage>("frontpage").catch(
    handleRequestError(context.req, null)
  );
  if (!page) return { notFound: true };

  const categories = await findAllMainCategories({ sort: "name:asc" }).catch(
    handleRequestError(context.req, [])
  );
  const stores = await findAllStores().catch(
    handleRequestError(context.req, [])
  );
  const randomStoreArray = await findRandomStores(1).catch(
    handleRequestError(context.req, [])
  );
  const { data: featuredProducts } = await getProducts({
    filters: { isFeatured: { $eq: true } },
  }).catch(
    handleRequestError(context.req, { data: [] } as { data: StrapiProduct[] })
  );

  if (featuredProducts.length < 15) {
    const rest = 15 - featuredProducts.length;
    const response = await getProducts({
      limit: rest,
      filters: {
        id: { $notIn: featuredProducts.map((product) => product.id) },
      },
      random: true,
    }).catch(
      handleRequestError(context.req, { data: [] } as { data: StrapiProduct[] })
    );

    featuredProducts.push(...response.data);
  }

  const randomStore = randomStoreArray.length > 0 ? randomStoreArray[0] : null;

  return {
    props: {
      page,
      categories,
      stores: randomSort(stores),
      randomStore,
      featuredProducts: randomSort(featuredProducts),
    },
  };
};

export default function Home({
  page,
  categories,
  stores,
  randomStore,
  featuredProducts,
}: ServerSideProps) {
  const {
    welcomeTitle,
    welcomeSubtitle,
    exploreTitle,
    exploreSubtitle,
    exploreMediaBackground,
    featuredProductsTitle,
    featuredProductsSubtitle,
    newStoresTitle,
    newStoresSubtitle,
  } = page.attributes;

  const { onVisitDifferentStore } = useSingleStoreAtom();

  useEffect(() => {
    onVisitDifferentStore(null);
  }, []);

  return (
    <>
      <SectionHeading>
        <SectionHeading.Title className="mb-5">
          {welcomeTitle}
        </SectionHeading.Title>
        <SectionHeading.Subtitle className="mb-20">
          {welcomeSubtitle}
        </SectionHeading.Subtitle>
      </SectionHeading>
      <Products categories={categories} className="mb-20" />
      <ExploreSection
        title={exploreTitle}
        subtitle={exploreSubtitle}
        media={exploreMediaBackground.data}
        store={randomStore}
      />
      <FeaturedProductsSection
        title={featuredProductsTitle}
        subtitle={featuredProductsSubtitle}
        products={featuredProducts}
        className="mb-20"
      />
      <NewStoresSection
        title={newStoresTitle}
        subtitle={newStoresSubtitle}
        stores={stores}
        className="mb-20"
      />
    </>
  );
}
