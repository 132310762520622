import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/css/a11y";
import { ReactNode } from "react";
import { NAVIGATION_OPTIONS, SliderButton } from "../SliderButton";

export type SliderProps = {
  elements: SliderElement[];
  slideClass?: string;
};
export type SliderElement = {
  id: string | number;
  component: ReactNode;
};

const breakpoints = {
  320: { slidesPerView: 1.2 },
  640: { slidesPerView: 2.2 },
  900: { slidesPerView: 3.2 },
  1200: { slidesPerView: 4.2 },
};

function Slider({ elements, slideClass }: SliderProps) {
  if (elements.length === 0) return null;
  return (
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={20}
      navigation={NAVIGATION_OPTIONS}
      breakpoints={breakpoints}
    >
      <SliderButton type="left" />
      <SliderButton type="right" />
      {elements.map((element) => (
        <SwiperSlide key={element.id} className={slideClass}>
          {element.component}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export { Slider };
