import { Card } from "../Card";

import "swiper/swiper.min.css";
import "swiper/css/a11y";
import { Slider } from "../Slider";
import { StrapiProduct } from "~/shared-types";
import { getRoute } from "~/utils/getRoute";
import { getOfferDetails } from "~/utils/getOfferDetails";
import Link from "next/link";
import { ProductCardImage } from "../ProductCardImage";

type ProductsSliderProps = {
  products: StrapiProduct[];
  className?: string;
};

const getProductSlide = (product: StrapiProduct) => {
  const store = product.attributes.store?.data;

  const linkToProduct = getRoute("/products/:productId", {
    productId: product.id,
  });
  return {
    id: product.id,
    component: (
      <Card key={product.id} className="w-full">
        <ProductCardImage product={product} />

        <Card.Vendor store={store} />
        <Link href={linkToProduct}>
          <Card.Title>{product.attributes.name}</Card.Title>
          <Card.Price
            offerDetails={getOfferDetails(product)}
            price={product.attributes.price}
            oneLine
          />
        </Link>
      </Card>
    ),
  };
};

function ProductsSlider({ products, className }: ProductsSliderProps) {
  return (
    <div className={className}>
      <Slider elements={products.map(getProductSlide)} />
    </div>
  );
}

export { ProductsSlider };
