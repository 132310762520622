import classNames from "classnames";
import Button from "../Button";
import Icon from "~/assets/icons/Icon";
import { StrapiStore } from "~/shared-types";

type NavigationButtonProps = {
  store: StrapiStore;
  className?: string;
};

export const NavigationButton = ({
  store,
  className,
}: NavigationButtonProps) => {
  const { shownAddress, acceptsPickUp } = store.attributes;

  if (
    !acceptsPickUp ||
    !shownAddress?.city ||
    !shownAddress?.postCode ||
    !shownAddress?.line1
  )
    return null;

  const geoLink = `https://maps.google.com/?q=${shownAddress.line1} ${shownAddress.postCode} ${shownAddress.city}`;

  return (
    <Button
      className={classNames("flex gap-2 items-center", className)}
      href={geoLink}
      target="_blank"
      variant="primary-light"
      size="lg"
    >
      <Icon name="navigation" className="h-3 w-3 text-secondary-600" />
      Find vej
    </Button>
  );
};
