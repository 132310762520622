import { Favorite, useFavorites } from "~/atoms/favorites";
import Button from "../Button";
import Icon from "~/assets/icons/Icon";
import classNames from "classnames";

type Props = {
  favorite: Favorite;
  className?: string;
};

export const FavoriteButton = ({ favorite, className }: Props) => {
  const { toggleFavorite, findMatch } = useFavorites();

  const isFavorite = findMatch(favorite);

  return (
    <Button
      className={classNames("bg-secondary-100 p-2", className)}
      onClick={() => toggleFavorite(favorite)}
      title={isFavorite ? "Fjern fra favoritter" : "Tilføj til favoritter"}
    >
      <div className="text-primary-400 relative w-6 h-6">
        <Icon
          className={classNames(
            "absolute w-6 h-6 transition-opacity",
            isFavorite ? "opacity-100" : "opacity-0"
          )}
          name="heartFilled"
        />
        <Icon
          className={classNames(
            "absolute w-6 h-6 transition-opacity",
            isFavorite ? "opacity-0" : "opacity-100"
          )}
          name="heart"
        />
      </div>
    </Button>
  );
};
