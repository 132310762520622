import { IconType } from "~/assets/icons/iconPaths";
import { Text } from "../Text";
import Icon from "~/assets/icons/Icon";
import classNames from "classnames";
import { showToast } from "~/utils/toast";
import Button from "../Button";
import { useOnClickOutside, useToggle } from "usehooks-ts";
import { StrapiProduct } from "~/shared-types";
import { getFullPath } from "~/utils/getFullPath";
import { useRef } from "react";

type ShareButtonProps = {
  onShare: () => void;
  icon: IconType;
  buttonClassCame?: string;
  iconClassName?: string;
  label: string;
};

const ShareButton = ({
  onShare,
  icon,
  buttonClassCame,
  iconClassName,
  label,
}: ShareButtonProps) => {
  return (
    <div className="flex flex-col items-center">
      <Button
        title={`Del via: ${label}`}
        className={classNames("p-3 rounded-full", buttonClassCame)}
        onClick={onShare}
      >
        <Icon
          name={icon}
          className={classNames(
            "min-w-6 w-6 h-6 pointer-events-none",
            iconClassName
          )}
        />
      </Button>
      <Text className="text-2xs mt-2">{label}</Text>
    </div>
  );
};

type Props = {
  className?: string;
  product: StrapiProduct;
};

export const ShareBox = ({ className, product }: Props) => {
  const [open, toggleOpen, setOpen] = useToggle(false);
  const shareBoxRef = useRef(null);

  const fullPath = getFullPath("/produkter/" + product.id);

  const onEmailShare = () => {
    const subject = `${product.attributes.name} - Butik Danmark`;
    const body = `Hej, jeg fandt dette produkt på Butik Danmark og tænkte, at du måske ville være interesseret i det: ${fullPath}`;
    window.open(`mailto:?subject=${subject}&body=${body}`);
  };

  const onLinkShare = () => {
    navigator.clipboard.writeText(fullPath);
    showToast("success", "Link kopieret til udklipsholderen");
  };

  const onFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${fullPath}`,
      "_blank",
      "width=600, height=400, scrollbars=no, popup=1"
    );
  };

  useOnClickOutside(shareBoxRef, () => {
    setOpen(false);
  });

  return (
    <div className="md:relative" ref={shareBoxRef}>
      <Button
        className={classNames(
          "p-2",
          open ? "bg-primary" : "bg-secondary-100",
          className
        )}
        onClick={toggleOpen}
        title="Del produkt"
      >
        <div
          className={classNames(
            "w-6 h-6 transition-colors pointer-events-none",
            open ? "text-white" : "text-primary-400"
          )}
        >
          <Icon
            className={classNames("w-6 h-6 pointer-events-none")}
            name="share"
          />
        </div>
      </Button>

      <div
        className={classNames(
          "fixed md:absolute z-10 left-[50%] top-[50%] md:top-auto transform -translate-x-1/2 -translate-y-1/2 md:translate-y-0 bg-white px-6 py-5 mt-3 rounded-md shadow-lg transition-all duration-300",
          open ? "opacity-1" : "opacity-0 pointer-events-none"
        )}
      >
        <Text className="text-center">Del produkt:</Text>

        <div className="flex flex-col md:flex-row gap-8 mt-4">
          <ShareButton
            onShare={onEmailShare}
            icon="paperPlane"
            buttonClassCame="bg-secondary-200"
            iconClassName="text-black"
            label="Email"
          />

          <ShareButton
            onShare={onLinkShare}
            icon="link"
            buttonClassCame="bg-secondary-900"
            iconClassName="text-white"
            label="Link"
          />

          <ShareButton
            onShare={onFacebookShare}
            icon="facebook"
            buttonClassCame="bg-info"
            iconClassName="text-white"
            label="Facebook"
          />
        </div>
      </div>
    </div>
  );
};
